<template>
    <div class="mt-2">
        <div>
            <h2 class="text-xl font-semibold text-center">Cotización</h2>
        </div>
        <div class="grid grid-cols-3 text-sm my-2">
            <div class="text-left">
                Nº cotización:
            </div>
            <div class="text-right col-span-2">
                {{ datos.numeroCotz }}
            </div>
            <div class="text-left">
                Fecha:
            </div>
            <div class="text-right col-span-2">
                {{ datos.fecha }}
            </div>
            <div class="text-left">
                Nombre:
            </div>
            <div class="text-right col-span-2">
                {{ datos.nombre }}
            </div>
        </div>
        <div class="grid grid-cols-2 my-2">
            <div class="text-left col-span-2 text-base">
                Detalle lentes
            </div>
            <template v-for="(lente,indice) in datos.lentes" :key="indice">
                <div class="text-left text-sm col-span-2 font-semibold">
                    {{ `${lente.tipo}:` }}
                </div>
                <div class="text-left text-sm">
                    Armazón:
                </div>
                <div class="text-right text-sm">
                    {{ lente.armazon }}
                </div>
                <div class="text-left text-sm">
                    Cristal:
                </div>
                <div class="text-right text-sm">
                    {{ lente.cristal }}
                </div>
                <div class="text-left text-sm">
                    Filtro:
                </div>
                <div class="text-right text-sm">
                    {{ lente.filtro }}
                </div>
                <div 
                class="text-left text-sm"
                v-if="lente.tipo == 'Multifocal'"
                >
                    T.multifocal:
                </div>
                <div 
                class="text-right text-sm"
                v-if="lente.tipo == 'Multifocal'"
                >
                    {{ lente.tipoMultifocal }}
                </div>
                <div class="text-left text-sm">
                    Precio:
                </div>
                <div class="text-right text-sm">
                    {{ this.formatearCifra(lente.valor) }}
                </div>
            </template>
            <div class="text-left text-xl font-semibold">
                Total:
            </div>
            <div class="text-right text-xl font-semibold">
                {{ `$ ${this.formatearCifra(datos.total)}` }}
            </div>
        </div>
    </div>
</template>

<script>
import { separarPorMiles } from "@/utils/helpers";

export default {
    methods: {
        formatearCifra(cifra) {
            return separarPorMiles(cifra);
        }
    },
    props: {
        datos: {
            type: Object,
            default: null
        }
    }
}
</script>